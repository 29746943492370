import React, { useEffect, useState } from 'react';
import { View, Text, Button, TouchableOpacity, ScrollView, Modal, TextInput, Alert } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import { COMPANY_SEARCH, SUBMIT_RESULT } from '../utils/WebUtils';
import Autocomplete from '@mui/material/Autocomplete';//https://mui.com/material-ui/react-autocomplete/
import TextField from '@mui/material/TextField';
import { styles, ids } from '../utils/styles';
import translations from '../utils/strings';

function UserDetails({ route, navigation }) {
    var companyId = "", result = "", field = "";
    if (route && route.params && route.params.id) {
        companyId = route.params.id
    }
    if (route && route.params && route.params.result) {
        result = route.params.result;
    }
    if (route && route.params && route.params.field) {
        field = route.params.field;
    }
    const language = route.params.language;

    const [userEmail, setUserEmail] = useState("");
    const [userName, setUserName] = useState("");
    const [userPhone, setUserPhone] = useState("");

    const [selectedItem, setSelectedItem] = useState(null);
    const [companyList, setCompanyList] = useState([]);

    useEffect(() => {
        // restartBtn();
    }, []);

    const callSubmitResult = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "company_id": companyId,
                "user_name": userName,
                "user_email": userEmail,
                "user_phone": userPhone,
                "result": result,
                "field": field
            });
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(SUBMIT_RESULT, requestOptions)
                .then(response => response.json())
                .then(result => {
                    alert('Your record successfully saved!');
                    navigation.navigate('CompanySearch')
                })
                .catch(error => {
                    alert('Please try again to submit your record');
                });
        } catch (err) {
        }
    }

    const callCompany = async (search) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "company_name": search
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(COMPANY_SEARCH, requestOptions)
                .then(response => response.json())
                .then(result => {
                    companyList.length = 0;
                    for (var i = 0; i < result.records.length; i++) {
                        companyList.push({
                            label: result.records[i].company_name,
                            id: result.records[i].company_id
                        });
                    }
                    setCompanyList(companyList);
                })
                .catch(error => {
                });
        } catch (err) {
        }
    }

    return (
        <View style={styles.add_user_parent} dataSet={{ media: ids.add_user_parent }}>
            <View style={styles.add_user_child} dataSet={{ media: ids.add_user_child }}>
                <TextInput
                    onChangeText={setUserName}
                    value={userName}
                    style={styles.add_user_input} dataSet={{ media: ids.add_user_input }}
                    placeholder={translations[language]["enter_name"]} />
                <TextInput
                    onChangeText={setUserEmail}
                    value={userEmail}
                    style={styles.add_user_input_margin} dataSet={{ media: ids.add_user_input_margin }}
                    placeholder={translations[language]["enter_email"]} />
                <TextInput
                    onChangeText={(val) => { setUserPhone(val.replace(/[^0-9]/g, '')) }}
                    value={userPhone}
                    style={styles.add_user_input_margin} dataSet={{ media: ids.add_user_input_margin }}
                    placeholder={translations[language]["enter_phone"]} />
                <Text
                    style={styles.add_user_submit_button} dataSet={{ media: ids.add_user_submit_button }}
                    onPress={() => {
                        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
                        if (userName == "") {
                            alert(translations[language]['please_enter_name']);
                        } else if (userEmail == "") {
                            alert(translations[language]['please_enter_email']);
                        } else if (reg.test(userEmail) === false) {
                            alert(translations[language]['please_enter_proper_email']);
                        } else if (userPhone == "") {
                            alert(translations[language]['please_enter_phone']);
                        } else {
                            callSubmitResult();
                        }
                    }}>
                    {translations[language]['submit']}
                </Text>
                {
                    companyId == "" ?
                        <Autocomplete
                            style={styles.add_user_autocomplete}
                            disablePortal
                            id="combo-box-demo"
                            options={companyList}
                            sx={{ width: wp("20%") }}
                            onChange={(event, value) => {
                                setSelectedItem(value);
                            }}
                            onInputChange={(event, value, reason) => {
                                if (reason === 'input') {
                                    callCompany(value);
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="Select Company" />} />
                        : null
                }
            </View>
        </View>
    );
};

export default UserDetails;