import React, { useEffect, useState, } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { styles,ids } from '../utils/styles';

// Johney&johney@depp.com&9876543210&Leadership : 9 Competence : 11 Organization : 13 Assertiveness : 8 Intellectual Openness : 14 Creativity/Originality : 9 Problem-solving : 12 Teamwork/Citizenship : 15 Initiative : 11 Adaptability/Flexibility : 8
function DisplayUserDetails({ route }) {
    var userDetails = "";
    const [userName, setUserName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userPhone, setUserPhone] = useState("");
    const [userResult, setUserResult] = useState("");

    useEffect(() => {
        // userDetails = decodeURIComponent("%7B%0A%22userName%22%3A%22Johney%22%2C%0A%22email%22%3A%22johney%40depp.com%22%2C%0A%22phone%22%3A%229876543210%22%2C%0A%22result%22%3A%22Leadership%20%3A%209%20Competence%20%3A%2011%20Organization%20%3A%2013%20Assertiveness%20%3A%208%20Intellectual%20Openness%20%3A%2014%20Creativity%2FOriginality%20%3A%209%20Problem-solving%20%3A%2012%20Teamwork%2FCitizenship%20%3A%2015%20Initiative%20%3A%2011%20Adaptability%2FFlexibility%20%3A%208%22%0A%7D");
        // userDetails = decodeURIComponent("%7B%22userName%22%3A%22ramij+test%22%2C%22email%22%3A%22ramijm%40alakmalak.com%22%2C%22phone%22%3A%221234567890%22%2C%22result%22%3A%22Creativity%2FOriginality+%3A++12%5CnIntellect+%3A++12%5CnAesthetic+Appreciation%2FArtistic+Interests+%3A++12%5CnOrganization+%3A++12%5CnImagination+%3A++12%5CnCompetence+%3A++12%5CnEmotionality+%3A++12%5CnCooperation+%3A++12%5CnOpenness+to+Experience+%3A++7%5Cn%22%2C%22field%22%3A%22Website+HTML+Developer%22%7D");
        // userDetails = JSON.parse(userDetails);
        // setUserName(userDetails.userName);
        // setUserEmail(userDetails.email);
        // setUserPhone(userDetails.phone);
        // setUserResult(userDetails.result);
        if (route.params) {
            userDetails = decodeURIComponent(route.params.id);
            userDetails = JSON.parse(userDetails);
            setUserName(userDetails.userName);
            setUserEmail(userDetails.email);
            setUserPhone(userDetails.phone);
            setUserResult(userDetails.result);
        }
    }, [userDetails]);

    return (
        <View style={styles.display_user_parent1} dataSet={{media:ids.display_user_parent1}}>
            <View style={styles.display_user_parent2}  dataSet={{media:ids.display_user_parent2}}>
                <View style={styles.display_user_parent3}  dataSet={{media:ids.display_user_parent3}}>
                    <Text style={styles.display_user_label}  dataSet={{media:ids.display_user_label}}>
                        User Name :
                    </Text>
                    <Text style={styles.display_user_value}  dataSet={{media:ids.display_user_value}}>
                        {userName.replaceAll("+"," ")}
                    </Text>
                </View>
                <View style={styles.display_user_parent3}  dataSet={{media:ids.display_user_parent3}}>
                    <Text style={styles.display_user_label}  dataSet={{media:ids.display_user_label}}>
                        Email :
                    </Text>
                    <Text style={styles.display_user_value}  dataSet={{media:ids.display_user_value}}>
                        {userEmail.replaceAll("+"," ")}
                    </Text>
                </View>
                <View style={styles.display_user_parent3}  dataSet={{media:ids.display_user_parent3}}>
                    <Text style={styles.display_user_label}  dataSet={{media:ids.display_user_label}}>
                        Phone :
                    </Text>
                    <Text style={styles.display_user_value}  dataSet={{media:ids.display_user_value}}>
                        {userPhone.replaceAll("+"," ")}
                    </Text>
                </View>

                <Text style={styles.display_user_label}  dataSet={{media:ids.display_user_label}}>
                    Result :
                </Text>
                <Text style={styles.display_user_result_value}  dataSet={{media:ids.display_user_result_value}}>
                    {userResult.replaceAll("+"," ")}
                </Text>
            </View>
        </View>
    );
};

// const styles = StyleSheet.create({
//     parent1: { justifyContent: 'center', alignItems: 'center', backgroundColor: "white", alignSelf: "center", height: hp("100%") },
//     parent2: { backgroundColor: "#E3F2FD", justifyContent: 'center', alignItems: 'flex-start', width: wp("50%"), height: hp("50%"), paddingHorizontal: wp("1%") },
//     parent3: { flexDirection: "row" },
//     label: { fontSize: hp("3%"), color: "black", paddingVertical: wp("1%"), marginStart: wp("1%"), fontWeight: "bold" },
//     value: { fontSize: hp("3%"), color: "#454545", paddingVertical: wp("1%"), marginStart: wp("1%") },
// });

export default DisplayUserDetails;