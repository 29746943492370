import React, { useEffect, useState } from 'react';
import { View, Text, Button, TouchableOpacity, ScrollView, Modal, Picker, Image } from 'react-native';
import { styles, ids } from '../utils/styles';
import translations from '../utils/strings';

// const data = require('./data.json');
// const data = require('./data/team_leader_data.json');
var data = require('../data/team_leader_data.json');
// const data = require('../data/test_team_leader_data.json');

function QuizScreen({ route, navigation }) {
  const [language, setLanguage] = useState("eng");

  const companyId = route.params.id ? route.params.id : "";
  const field = route.params.field ? route.params.field : "";
  const fieldName = route.params.fieldName ? route.params.fieldName : "";
  // const language = route.params.language;
  switch (field) {
    case '1':
      data = require('../data/account_data.json');
      break;
    case '2':
      data = require('../data/digital_marketing_data.json');
      break;
    case '3':
      data = require('../data/hr_executive_data.json');
      break;
    case '4':
      data = require('../data/hr_recruiter_data.json');
      break;
    case '5':
      data = require('../data/layout_designer_web_data.json');
      break;
    case '6':
      data = require('../data/project_manager_data.json');
      break;
    case '7':
      data = require('../data/qa_tester_data.json');
      break;
    case '8':
      data = require('../data/team_leader_data.json');
      break;
    case '9':
      data = require('../data/tl_digital_marketing_data.json');
      break;
    case '10':
      data = require('../data/ui_ux_data.json');
      break;
    case '11':
      data = require('../data/website_dev_data.json');
      break;
    case '12':
      data = require('../data/website_html_data.json');
      break;
    default:
      data = require('../data/team_leader_data.json');
      break;
  }

  const [qNo, setQNo] = useState(0);
  const [optSelected, setOptSelected] = useState(0);
  const [ansList, setAnsList] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const [displayAns, setDisplayAns] = useState("");

  useEffect(() => {
    if (route.params.language){
      setLanguage(route.params.language);
    }
    // setLanguage(route.params.language);
    // restartBtn();
  }, []);

  const nextBtn = () => {
    if (optSelected === 0) {
      alert(translations[language]['please_select_one_option']);
      return;
    }

    setAnsList([...ansList, optSelected]);

    if (qNo === data.question.length - 1) {
      // setShowResult(true);
      displayResult();
    } else {
      setQNo(qNo + 1);
      setOptSelected(0);
    }
  };

  const restartBtn = () => {
    setQNo(0);
    displayOptions();
    displayNumberOfQuestion();
    displayQuestion();
    ansList.length = 0;
  };

  // Display options for answer
  const displayOptions = () => {
    var data_temp = data.options[0];
    switch (language) {
      case "eng": {
        data_temp = data.options[0];
        break;
      }
      case "hin": {
        data_temp = data.options_hin[0];
        break;
      }
      case "guj": {
        data_temp = data.options_guj[0];
        break;
      }
    }
    return data_temp.map((option, index) => (
      <TouchableOpacity
        key={index}
        style={[styles.display_questions_option_back, { backgroundColor: optSelected === index + 1 ? 'green' : 'gray' }]}
        dataSet={{ media: ids.display_questions_option_back }}
        onPress={() => {
          setOptSelected(index + 1);
          // nextBtn();
        }
        }>
        <Text style={styles.display_questions_option_text} dataSet={{ media: ids.display_questions_option_text }}>{option}</Text>
      </TouchableOpacity>
    ));
  };

  // Display number of questions
  const displayNumberOfQuestion = () => {
    return (
      <View style={styles.display_que_parent_quiz} dataSet={{ media: ids.display_que_parent_quiz }}>
        <Text style={styles.display_que_quiz} dataSet={{ media: ids.display_que_quiz }}>{qNo + 1} of {data.question.length}</Text>
      </View>
    );
  };

  const getQuestions = (qNo) => {
    switch (language) {
      case 'eng':
        return data.question[qNo].question
      case 'hin':
        return data.question[qNo].question_hin == "NA" ? data.question[qNo].question : data.question[qNo].question_hin
      case 'guj':
        return data.question[qNo].question_guj == "NA" ? data.question[qNo].question : data.question[qNo].question_guj
    }
  }

  // display question
  const displayQuestion = () => {
    return (
      <View style={styles.display_questions_quiz} dataSet={{ media: ids.display_questions_quiz }}>
        <Text style={styles.display_questions_quiz_text} dataSet={{ media: ids.display_questions_quiz_text }}>
          {
            getQuestions(qNo)
          }
        </Text>
      </View>
    );
  };

  // main logic after complete test
  const displayResult = () => {
    // setShowSubmit(true);

    let count = 0;
    // typescore update length same size as typeinfo size
    const typeScores = Array(13).fill(0);

    ansList.forEach(answer => {
      const question = data.question[count];
      const answerMath = question.math === "+" ? answer : 5 - (answer - 1);
      typeScores[parseInt(question.type) - 1] += answerMath;
      if (question.type2) {
        typeScores[parseInt(question.type2) - 1] += answerMath;
      }
      count += 1;
    });

    // Type information
    const typeInfo = data.parameters[0];

    let displayTempAns = "";
    for (let i = 0; i < typeInfo.length; i++) {
      displayTempAns += `${typeInfo[i]} :  ${typeScores[i]}\n`;
    }

    setDisplayAns(displayTempAns);

    navigation.navigate('UserDetails', { id: companyId, result: displayTempAns, language: language, field: fieldName });
    // alert(displayAns);
  };

  return (
    <View style={styles.parent_quiz} dataSet={{ media: ids.parent_quiz }}>
      <View style={styles.child_quiz} dataSet={{ media: ids.child_quiz }}>
        <ScrollView>
          <View style={styles.child2_quiz} dataSet={{ media: ids.child2_quiz }}>
            {displayNumberOfQuestion()}
            {displayQuestion()}
            {displayOptions()}
            <Text
              style={styles.display_questions_next_text} dataSet={{ media: ids.display_questions_next_text }}
              onPress={nextBtn}>
              {translations[language]["next"]}
            </Text>

            <Text
              style={styles.display_questions_reset_text} dataSet={{ media: ids.display_questions_reset_text }}
              onPress={() => {
                if (confirm(translations[language]["reset_alert"])) {
                  restartBtn()
                }
                // restartBtn
              }}>
              {translations[language]["restart"]}
            </Text>
          </View>
        </ScrollView>
        <View
          style={styles.language_parent}>
          <Image
            style={styles.language_image}
            dataSet={{ media: ids.language_image }}
            source={require('../images/world.png')}
          />
          <Picker
            style={styles.language_dropdown}
            dataSet={{ media: ids.language_dropdown }}
            selectedValue={language}
            onValueChange={(value, index) => {
              setLanguage(value);
            }}>
            <Picker.Item label="English" value="eng" />
            <Picker.Item label="Gujarati" value="guj" />
            {/* <Picker.Item label="Hindi" value="hin" /> */}
          </Picker>
        </View>
        <Text style={styles.display_fieldname_quiz} dataSet={{ media: ids.display_que_quiz }}>{fieldName}</Text>

      </View>
    </View>
  );
};

export default QuizScreen;