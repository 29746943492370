import React, { useEffect, useState } from 'react';
import { View, Text, Picker, Image } from 'react-native';
import Autocomplete from '@mui/material/Autocomplete';//https://mui.com/material-ui/react-autocomplete/
import TextField from '@mui/material/TextField';
import { COMPANY_SEARCH } from '../utils/WebUtils';
import { styles, ids } from '../utils/styles';
import translations from '../utils/strings';

function CompanySearch({ route, navigation }) {
    // const { id } = (route == undefined || route.params == undefined || route.params == "undefined") ? "" : route.params;

    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedFieldItem, setSelectedFieldItem] = useState(null);
    const [companyList, setCompanyList] = useState([]);
    const fieldList = [
        {
            label: "Account",
            id: "1"
        },
        {
            label: "Digital Marketing",
            id: "2"
        },
        {
            label: "HR Executive",
            id: "3"
        },
        {
            label: "HR Recruiter",
            id: "4"
        },
        {
            label: "Web Layout Design",
            id: "5"
        },
        {
            label: "Project Manager",
            id: "6"
        },
        {
            label: "QA/Tester",
            id: "7"
        },
        {
            label: "Team Leader",
            id: "8"
        },
        {
            label: "TL Digital Marketing",
            id: "9"
        },
        {
            label: "UI/UX",
            id: "10"
        },
        {
            label: "Website Developer",
            id: "11"
        },
        {
            label: "Website HTML Developer",
            id: "12"
        }
    ];
    const [companyId, setCompanyId] = useState("");
    const [language, setLanguage] = useState("eng");
    var userDetails = "";
    useEffect(() => {
        if (route.params) {
            if (route.params.id !== "undefined") {
                userDetails = decodeURIComponent(route.params.id);
                userDetails = JSON.parse(userDetails);
                setCompanyId(userDetails.companyId);
                navigation.navigate('QuizScreen', { id: userDetails.companyId })
            }
        }
    }, [language]);

    const callCompany = async (search) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "admin_name": search
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(COMPANY_SEARCH, requestOptions)
                .then(response => response.json())
                .then(result => {
                    companyList.length = 0;
                    for (var i = 0; i < result.records.length; i++) {
                        companyList.push({
                            label: result.records[i].admin_name,
                            id: result.records[i].admin_id
                        });
                    }
                    setCompanyList(companyList);
                })
                .catch(error => {
                });
        } catch (err) {
        }
    }

    return (
        <View style={styles.parent} dataSet={{ media: ids.parent }}>
            <View style={styles.child_1} dataSet={{ media: ids.child_1 }}>
                <View
                    style={styles.language_parent}>
                    <Image
                        style={styles.language_image}
                        dataSet={{ media: ids.language_image }}
                        source={require('../images/world.png')}
                    />
                    <Picker
                        style={styles.language_dropdown}
                        dataSet={{ media: ids.language_dropdown }}
                        selectedValue={language}
                        onValueChange={(value, index) => {
                            setLanguage(value);
                        }}>
                        <Picker.Item label="English" value="eng" />
                        <Picker.Item label="Gujarati" value="guj" />
                        {/* <Picker.Item label="Hindi" value="hin" /> */}
                    </Picker>
                </View>
                <Autocomplete
                    style={styles.auto_complete_field}
                    disablePortal
                    id="combo-box-demo"
                    options={fieldList}
                    sx={styles.auto_complete_text}
                    onChange={(event, value) => {
                        setSelectedFieldItem(value);
                    }}
                    onInputChange={(event, value, reason) => {
                        if (reason === 'input') {
                        }
                    }}
                    renderInput={(params) => <TextField {...params} label={translations[language]["select_field"]} />} />
                <Autocomplete
                    style={styles.auto_complete}
                    disablePortal
                    id="combo-box-demo"
                    options={companyList}
                    sx={styles.auto_complete_text}
                    onChange={(event, value) => {
                        setSelectedItem(value);
                    }}
                    onInputChange={(event, value, reason) => {
                        if (reason === 'input') {
                            callCompany(value);
                        }
                    }}
                    renderInput={(params) => <TextField {...params} label={translations[language]["select_company"]} />} />

                <Text
                    style={styles.button}
                    dataSet={{ media: ids.button }}
                    onPress={() => {
                        if (selectedItem == null) {
                            alert(translations[language]['please_select_company']);
                        } else if (selectedFieldItem == null) {
                            alert(translations[language]['please_select_field']);
                        } else {
                            navigation.navigate('QuizScreen', { id: selectedItem.id, field: selectedFieldItem.id, fieldName: selectedFieldItem.label, language: language})
                            // navigation.navigate('UserDetails', { id: selectedItem.id, field: selectedFieldItem.id, language: language})
                            // navigation.navigate('DisplayUserDetails')
                        }
                    }}>
                    {translations[language]["next"]}
                </Text>
            </View>
        </View >
    );
};

export default CompanySearch;