import { Dimensions } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
import StyleSheet from 'react-native-media-query';


const { ids, styles } = StyleSheet.create({
    // CompanySearch.js
    parent: {
        justifyContent: 'center',
        alignItems: 'center',
        height: hp("100%"),
        width: wp("100%"),
    },
    child_1: {
        backgroundColor: "#E3F2FD",
        justifyContent: 'center',
        alignItems: 'center',
        width: wp("70%"),
        height: hp("70%"),
    },
    auto_complete: { backgroundColor: "white", color: "black", width: wp("45%"), marginTop: hp("5%"), },
    auto_complete_field: { backgroundColor: "white", color: "black", width: wp("45%"), },
    auto_complete_text: { width: wp("20%") },
    button: { backgroundColor: "white", fontSize: hp("2.5%"), color: "white", backgroundColor: "#03A9F4", paddingVertical: hp("1%"), paddingHorizontal: wp("10%"), marginTop: hp("10%"), textAlign: "center", },
    language_parent: { position: "absolute", top: 0, end: 0, marginTop: hp("2%"), marginEnd: wp("2%"), justifyContent: "center", alignContent: "center", alignItems: "center", flex: 1, flexDirection: "row", },
    language_dropdown: { backgroundColor: "white", fontSize: hp("2%"), color: "black", paddingHorizontal: hp("0.1%"), paddingVertical: hp("1%"), textAlign: "center", },
    language_image: {
        width: wp("3%"), height: hp("6%"), resizeMode: "contain", margin: wp("0.5%"),
        '@media only screen and (max-width: 480px)': {
            width: wp("6%"), 
            height: hp("6%"),
            margin: wp("1%"),
        },
        '@media only screen and (min-width: 481px) and (max-width: 767px)': {
            width: wp("4%"), 
            height: hp("6%"),
            margin: wp("1%"),
        },
        '@media only screen and (min-width: 768px) and (max-width: 1024px)': {
            width: wp("3%"), 
            height: hp("6%"),
            margin: wp("1%"),
        },
        '@media only screen and (min-width: 1025px) and (max-width: 1280px)': {
            width: wp("2.5%"), 
            height: hp("6%"),
            margin: wp("1%"),
        },
        '@media only screen and (min-width: 1281px) and (max-width: 1366px)': {
            width: wp("2%"), 
            height: hp("6%"),
            margin: wp("1%"),
        },
        '@media only screen and (min-width: 1367px) and (max-width: 1680px)': {
            width: wp("2%"), 
            height: hp("6%"),
            margin: wp("1%"),
        },
        '@media only screen and (min-width: 1681px) and (max-width: 1920px)': {
            width: wp("2%"), 
            height: hp("6%"),
            margin: wp("1%"),
        },
        '@media only screen and (min-width: 1921px)': {
            width: wp("2%"), 
            height: hp("6%"),
            margin: wp("1%"),
        },        
    },

    // QuizScreen
    parent_quiz: { justifyContent: 'center', alignItems: 'center', backgroundColor: "white", alignSelf: "center", height: hp("100%"), width: wp("100%"), },
    child_quiz: { backgroundColor: "#E3F2FD", justifyContent: 'center', alignItems: 'center', width: wp("90%"), height: hp("90%") },
    child2_quiz: { backgroundColor: "#E3F2FD", justifyContent: 'center', alignItems: 'center', },
    display_que_parent_quiz: { marginVertical: hp("5%"), alignItems: "center" },
    display_que_quiz: { fontSize: hp("3%"), color: "#4CAF50", fontWeight: 'bold', textDecorationStyle: 'double', textDecorationLine: "underline" },
    display_que_quiz: { fontSize: hp("3%"), color: "#4CAF50", fontWeight: 'bold', textDecorationStyle: 'double', textDecorationLine: "underline" },
    display_fieldname_quiz: { fontSize: hp("2%"),position: "absolute", top: 0, start: 0, marginTop: hp("1%"), marginStart: wp("1%"), fontWeight: 'bold', textDecorationStyle: 'double', textDecorationLine: "underline" },
    display_questions_quiz: { margin: hp("2%") },
    display_questions_quiz_text: { fontSize: hp("2.5%"), color: "black", fontWeight: 'bold' },
    display_questions_option_back: {
        paddingVertical: hp("1%"), paddingHorizontal: wp("3%"), width: wp('70%'), marginVertical: hp("0.5%"),
        '@media only screen and (max-width: 480px)': {
            width: wp('70%'),
        },
        '@media only screen and (min-width: 481px) and (max-width: 767px)': {
            width: wp('60%'),
        },
        '@media only screen and (min-width: 768px) and (max-width: 1024px)': {
            width: wp('55%'),
        },
        '@media only screen and (min-width: 1025px) and (max-width: 1280px)': {
            width: wp('55%'),
        },
        '@media only screen and (min-width: 1281px) and (max-width: 1366px)': {
            width: wp('50%'),
        },
        '@media only screen and (min-width: 1367px) and (max-width: 1680px)': {
            width: wp('45%'), padding: hp('2%'),
        },
        '@media only screen and (min-width: 1681px) and (max-width: 1920px)': {
            width: wp('45%'), padding: hp('2%'),
        },
        '@media only screen and (min-width: 1921px)': {
            width: wp('45%'), padding: hp('2%'),
        },
    },
    display_questions_option_text: { fontSize: hp("2.5%"), color: 'white' },
    display_questions_next_text: {
        width: wp("40%"), backgroundColor: "white", fontSize: hp("2.5%"), color: "white", backgroundColor: "#03A9F4", paddingVertical: hp("1%"), marginTop: hp("5%"), textAlign: "center",
        '@media only screen and (max-width: 480px)': {
            width: wp("40%"),
        },
        '@media only screen and (min-width: 481px) and (max-width: 767px)': {
            width: wp("30%"),
        },
        '@media only screen and (min-width: 768px) and (max-width: 1024px)': {
            width: wp("25%"),
        },
        '@media only screen and (min-width: 1025px) and (max-width: 1280px)': {
            width: wp("20%"),
        },
        '@media only screen and (min-width: 1281px) and (max-width: 1366px)': {
            width: wp("20%"),
        },
        '@media only screen and (min-width: 1367px) and (max-width: 1680px)': {
            width: wp("20%"), padding: hp("1.5%"), fontSize: hp("3%")
        },
        '@media only screen and (min-width: 1681px) and (max-width: 1920px)': {
            width: wp("20%"), padding: hp("1.5%"), fontSize: hp("3%")
        },
        '@media only screen and (min-width: 1921px)': {
            width: wp("20%"), padding: hp("1.5%"), fontSize: hp("3%")
        },
    },
    display_questions_reset_text: {
        width: wp("40%"), backgroundColor: "white", fontSize: hp("2.5%"), color: "white", backgroundColor: "#B0BEC5", paddingVertical: hp("1%"), marginTop: hp("5%"), textAlign: "center",
        '@media only screen and (max-width: 480px)': {
            width: wp("40%"),
        },
        '@media only screen and (min-width: 481px) and (max-width: 767px)': {
            width: wp("30%"),
        },
        '@media only screen and (min-width: 768px) and (max-width: 1024px)': {
            width: wp("25%"),
        },
        '@media only screen and (min-width: 1025px) and (max-width: 1280px)': {
            width: wp("20%"),
        },
        '@media only screen and (min-width: 1281px) and (max-width: 1366px)': {
            width: wp("20%"),
        },
        '@media only screen and (min-width: 1367px) and (max-width: 1680px)': {
            width: wp("20%"), padding: hp("1.5%"), fontSize: hp("3%")
        },
        '@media only screen and (min-width: 1681px) and (max-width: 1920px)': {
            width: wp("20%"), padding: hp("1.5%"), fontSize: hp("3%")
        },
        '@media only screen and (min-width: 1921px)': {
            width: wp("20%"), padding: hp("1.5%"), fontSize: hp("3%")
        },
    },

    // User details
    add_user_parent: { justifyContent: 'center', alignItems: 'center', backgroundColor: "white", alignSelf: "center", height: hp("100%") },
    add_user_child: {
        backgroundColor: "#E3F2FD", justifyContent: 'center', alignItems: 'center', width: wp("80%"), height: hp("80%"),
    },
    add_user_input: {
        backgroundColor: "white", fontSize: hp("4%"), color: "black", paddingVertical: hp("2%"), paddingHorizontal: wp("3%"),
        '@media only screen and (max-width: 480px)': {
            width: wp("65%"), fontSize: hp("3%"), padding: hp("2%")
        },
        '@media only screen and (min-width: 481px) and (max-width: 767px)': {
            width: wp("45%"), fontSize: hp("3%"), padding: hp("2%")
        },
        '@media only screen and (min-width: 768px) and (max-width: 1024px)': {
            width: wp("45%"), fontSize: hp("3%"), padding: hp("2%")
        },
        '@media only screen and (min-width: 1025px) and (max-width: 1280px)': {
            width: wp("45%"), fontSize: hp("3.5%"), padding: hp("2%")
        },
        '@media only screen and (min-width: 1281px) and (max-width: 1366px)': {
            width: wp("45%"), fontSize: hp("3%"), padding: hp("2%")
        },
        '@media only screen and (min-width: 1367px) and (max-width: 1680px)': {
            width: wp("45%"), fontSize: hp("3%"), padding: hp("2%")
        },
        '@media only screen and (min-width: 1681px) and (max-width: 1920px)': {
            width: wp("45%"), fontSize: hp("3%"), padding: hp("2%")
        },
        '@media only screen and (min-width: 1921px)': {
            width: wp("45%"), fontSize: hp("3%"), padding: hp("2%")
        },
    },
    add_user_input_margin: {
        backgroundColor: "white", fontSize: hp("4%"), color: "black", paddingVertical: hp("2%"), paddingHorizontal: wp("3%"), marginTop: hp("3%"),
        '@media only screen and (max-width: 480px)': {
            width: wp("65%"), fontSize: hp("3%"), padding: hp("2%")
        },
        '@media only screen and (min-width: 481px) and (max-width: 767px)': {
            width: wp("45%"), fontSize: hp("3%"), padding: hp("2%")
        },
        '@media only screen and (min-width: 768px) and (max-width: 1024px)': {
            width: wp("45%"), fontSize: hp("3%"), padding: hp("2%")
        },
        '@media only screen and (min-width: 1025px) and (max-width: 1280px)': {
            width: wp("45%"), fontSize: hp("3.5%"), padding: hp("2%")
        },
        '@media only screen and (min-width: 1281px) and (max-width: 1366px)': {
            width: wp("45%"), fontSize: hp("3%"), padding: hp("2%")
        },
        '@media only screen and (min-width: 1367px) and (max-width: 1680px)': {
            width: wp("45%"), fontSize: hp("3%"), padding: hp("2%")
        },
        '@media only screen and (min-width: 1681px) and (max-width: 1920px)': {
            width: wp("45%"), fontSize: hp("3%"), padding: hp("2%")
        },
        '@media only screen and (min-width: 1921px)': {
            width: wp("45%"), fontSize: hp("3%"), padding: hp("2%")
        },
    },
    add_user_submit_button: {
        backgroundColor: "white", fontSize: hp("4%"), color: "white", backgroundColor: "#03A9F4", paddingVertical: hp("1%"), paddingHorizontal: wp("10%"), marginTop: hp("10%"), textAlign: "center",
        '@media only screen and (max-width: 480px)': {
            width: wp("30%"), padding: hp("1%"), fontSize: hp("2.5%")
        },
        '@media only screen and (min-width: 481px) and (max-width: 767px)': {
            width: wp("20%"), padding: hp("1%"), fontSize: hp("3%")
        },
        '@media only screen and (min-width: 768px) and (max-width: 1024px)': {
            width: wp("20%"), padding: hp("1%"), fontSize: hp("3%")
        },
        '@media only screen and (min-width: 1025px) and (max-width: 1280px)': {
            width: wp("23%"), padding: hp("1%")
        },
        '@media only screen and (min-width: 1281px) and (max-width: 1366px)': {
            width: wp("15%"), padding: hp("1.5%")
        },
        '@media only screen and (min-width: 1367px) and (max-width: 1680px)': {
            width: wp("15%"), padding: hp("1.5%")
        },
        '@media only screen and (min-width: 1681px) and (max-width: 1920px)': {
            width: wp("15%"), padding: hp("1.5%")
        },
        '@media only screen and (min-width: 1921px)': {
            width: wp("15%"), padding: hp("1.5%")
        },
    },
    add_user_autocomplete: {
        backgroundColor: "white", color: "black", marginTop: hp("5%"), width: wp("45%"),
    },

    // display user data

    display_user_parent1: { justifyContent: 'center', alignItems: 'center', backgroundColor: "white", alignSelf: "center", height: hp("100%") },
    display_user_parent2: {
        backgroundColor: "#E3F2FD", justifyContent: 'center', alignItems: 'flex-start', width: wp("90%"), height: hp("90%"), paddingHorizontal: wp("1%"),
        '@media only screen and (max-width: 480px)': {
            width: wp("90%"), height: hp("90%")
        },
        '@media only screen and (min-width: 481px) and (max-width: 767px)': {
            width: wp("90%"), height: hp("90%")
        },
        '@media only screen and (min-width: 768px) and (max-width: 1024px)': {
            width: wp("90%"), height: hp("90%")
        },
        '@media only screen and (min-width: 1025px) and (max-width: 1280px)': {
            width: wp("90%"), height: hp("90%")
        },
        '@media only screen and (min-width: 1281px) and (max-width: 1366px)': {
            width: wp("90%"), height: hp("90%")
        },
        '@media only screen and (min-width: 1367px) and (max-width: 1680px)': {
            width: wp("90%"), height: hp("90%")
        },
        '@media only screen and (min-width: 1681px) and (max-width: 1920px)': {
            width: wp("90%"), height: hp("90%")
        },
        '@media only screen and (min-width: 1921px)': {
            width: wp("90%"), height: hp("90%")
        },
    },
    display_user_parent3: { flexDirection: "row" },
    display_user_label: {
        fontSize: hp("3%"), color: "black", paddingVertical: wp("1%"), marginStart: wp("3%"), fontWeight: "bold",
        '@media only screen and (max-width: 480px)': {
            fontSize: hp("2.5%"),
        },
        '@media only screen and (min-width: 481px) and (max-width: 767px)': {
            fontSize: hp("2.5%"),
        },
        '@media only screen and (min-width: 768px) and (max-width: 1024px)': {},
        '@media only screen and (min-width: 1025px) and (max-width: 1280px)': {},
        '@media only screen and (min-width: 1281px) and (max-width: 1366px)': {},
        '@media only screen and (min-width: 1367px) and (max-width: 1680px)': {},
        '@media only screen and (min-width: 1681px) and (max-width: 1920px)': {},
        '@media only screen and (min-width: 1921px)': {},
    },
    display_user_value: {
        fontSize: hp("3%"), color: "#454545", paddingVertical: wp("1%"), marginEnd: wp("3%"),
        '@media only screen and (max-width: 480px)': {
            fontSize: hp("2.5%"),
        },
        '@media only screen and (min-width: 481px) and (max-width: 767px)': {
            fontSize: hp("2.5%"),
        },
        '@media only screen and (min-width: 768px) and (max-width: 1024px)': {},
        '@media only screen and (min-width: 1025px) and (max-width: 1280px)': {},
        '@media only screen and (min-width: 1281px) and (max-width: 1366px)': {},
        '@media only screen and (min-width: 1367px) and (max-width: 1680px)': {},
        '@media only screen and (min-width: 1681px) and (max-width: 1920px)': {},
        '@media only screen and (min-width: 1921px)': {},
    },
    display_user_result_value: {
        fontSize: hp("3%"), color: "#454545", paddingVertical: wp("1%"), marginHorizontal: wp("3%"),
        '@media only screen and (max-width: 480px)': {
            fontSize: hp("2.5%"),
        },
        '@media only screen and (min-width: 481px) and (max-width: 767px)': {
            fontSize: hp("2.5%"),
        },
        '@media only screen and (min-width: 768px) and (max-width: 1024px)': {},
        '@media only screen and (min-width: 1025px) and (max-width: 1280px)': {},
        '@media only screen and (min-width: 1281px) and (max-width: 1366px)': {},
        '@media only screen and (min-width: 1367px) and (max-width: 1680px)': {},
        '@media only screen and (min-width: 1681px) and (max-width: 1920px)': {},
        '@media only screen and (min-width: 1921px)': {},
    },
});

export { ids, styles };



// '@media only screen and (max-width: 480px)': {},
// '@media only screen and (min-width: 481px) and (max-width: 767px)': {},
// '@media only screen and (min-width: 768px) and (max-width: 1024px)': {},
// '@media only screen and (min-width: 1025px) and (max-width: 1280px)': {},
// '@media only screen and (min-width: 1281px) and (max-width: 1366px)': {},
// '@media only screen and (min-width: 1367px) and (max-width: 1680px)': {},
// '@media only screen and (min-width: 1681px) and (max-width: 1920px)': {},
// '@media only screen and (min-width: 1921px)': {},
// '@media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)': {},
// '@media only screen and (orientation: portrait)': {},
// '@media only screen and (orientation: landscape)': {},