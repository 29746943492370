import React, { useEffect, useState } from 'react';
import { NavigationContainer, } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import QuizScreen from './screens/QuizScreen.js';
import CompanySearch from './screens/CompanySearch.js';
import UserDetails from './screens/UserDetails.js';
import DisplayUserDetails from './screens/DisplayUserDetails.js';


const App = () => {
  const Stack = createNativeStackNavigator();

// https://reactnavigation.org/docs/configuring-links/

//Working deep link : https://personality.alakmalak.ca/display-user-details/123
  const linking = {
    prefixes: ['https://personality.alakmalak.ca', 'personality.alakmalak.ca://'],
    config: {
      screens: {
        CompanySearch: {
          path:'company-search/:id',
          parse: {
            id: (id) => `${id}`,
          },
          stringify: {
            id: (id) => id,
          },
        },
        QuizScreen: 'quiz',
        UserDetails: 'user-details/:id',
        DisplayUserDetails: {
          path:'display-user-details/:id',
          parse: {
            id: (id) => `${id}`,
          },
          stringify: {
            id: (id) => id,
          },
        },
      }
    },
  };

  return (
    <NavigationContainer linking={linking}>
    {/* <NavigationContainer> */}
      <Stack.Navigator initialRouteName={"CompanySearch"}>
        <Stack.Screen name="CompanySearch" component={CompanySearch} />
        <Stack.Screen name="QuizScreen" component={QuizScreen} />
        <Stack.Screen name="UserDetails" component={UserDetails} />
        <Stack.Screen name="DisplayUserDetails" component={DisplayUserDetails} />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default App;

// Links

// User detail screen open

//>> https://personality.alakmalak.ca/display-user-details/%7B%0A%22userName%22%3A%22Johney%22%2C%0A%22email%22%3A%22johney%40depp.com%22%2C%0A%22phone%22%3A%229876543210%22%2C%0A%22result%22%3A%22Leadership%20%3A%209%20Competence%20%3A%2011%20Organization%20%3A%2013%20Assertiveness%20%3A%208%20Intellectual%20Openness%20%3A%2014%20Creativity%2FOriginality%20%3A%209%20Problem-solving%20%3A%2012%20Teamwork%2FCitizenship%20%3A%2015%20Initiative%20%3A%2011%20Adaptability%2FFlexibility%20%3A%208%22%0A%7D

// 1. Add company and complete quiz (Flow 1)

//>> https://personality.alakmalak.ca

// 2. Direct Quiz and at the end fill all data, add company(Optional) and submit (Flow 2 same as Flow 4)

//>> https://personality.alakmalak.ca/company-search/%7B%0A%22companyId%22%3A%22%22%0A%7D

// 3. Direct Quiz with company id passed (Flow 3)

//>> https://personality.alakmalak.ca/company-search/%7B%0A%22companyId%22%3A%221%22%0A%7D

// 4. Direct Quiz without company id passed (Flow 4 same as Flow 2)

//>> https://personality.alakmalak.ca/company-search/%7B%0A%22companyId%22%3A%221%22%0A%7D
