const translations = {
    eng: {
        select_company: "Select Company",
        select_field: "Select Field",
        next: "Next",
        restart: "Restart",
        enter_name: "Enter Name",
        enter_email: "Enter Email",
        enter_phone: "Enter Phone",
        submit: "SUBMIT",
        please_enter_name: "Please enter name",
        please_enter_email: "Please enter email",
        please_enter_proper_email: "Please enter proper email",
        please_enter_phone: "Please enter phone",
        please_select_one_option: "Please select one option!",
        please_select_company: "Please select company!",
        please_select_field: "Please select field!",
        reset_alert: "Are you sure you want to reset?",
    },
    hin: {
        select_company: "कंपनी का चयन करें",
        select_field: "फ़ील्ड चुनें",
        next: "आगे",
        restart: "पुनः आरंभ करें",
        enter_name: "नाम दर्ज करें",
        enter_email: "ईमेल दर्ज करें",
        enter_phone: "फ़ोन दर्ज करें",
        submit: "जमा करना",
        please_enter_name: "कृपया नाम दर्ज करें",
        please_enter_email: "कृपया ईमेल दर्ज करें",
        please_enter_proper_email: "कृपया उचित ईमेल दर्ज करें",
        please_enter_phone: "कृपया फ़ोन दर्ज करें",
        please_select_one_option: "कृपया एक विकल्प चुनें!",
        please_select_company: "कृपया कंपनी चुनें!",
        please_select_field: "कृपया फ़ील्ड चुनें!",
        reset_alert: "क्या आप वाकई रीसेट करना चाहते हैं?",
    },
    guj: {
        select_company: "કંપની પસંદ કરો",
        select_field: "ક્ષેત્ર પસંદ કરો",
        next: "આગળ",
        restart: "પુનઃપ્રારંભ",
        enter_name: "નામ દાખલ કરો",
        enter_email: "ઈમેલ દાખલ કરો",
        enter_phone: "ફોન દાખલ કરો",
        submit: "સબમિટ કરો",
        please_enter_name: "કૃપા કરીને નામ દાખલ કરો",
        please_enter_email: "કૃપા કરીને ઈમેલ દાખલ કરો",
        please_enter_proper_email: "કૃપા કરીને યોગ્ય ઇમેઇલ દાખલ કરો",
        please_enter_phone: "કૃપા કરીને ફોન દાખલ કરો",
        please_select_one_option: "કૃપા કરીને એક વિકલ્પ પસંદ કરો!",
        please_select_company: "કૃપા કરીને કંપની પસંદ કરો!",
        please_select_field: "કૃપા કરીને ક્ષેત્ર પસંદ કરો!",
        reset_alert: "શું તમે ખરેખર રીસેટ કરવા માંગો છો?",
    },
    // Add more languages as needed
};

export default translations;